<template>
  <div class="bg-grey">
    <div class="container-fluid profile-user job-create customized-form px-1 px-md-3 pt-4 pb-3">
      <div class="row">
        <div class="col-md-12">
          <div class="mt-3 mt-md-5 grid-box">
            <div>
              <div class="grid-box">
                <h4 class="heading">Virtual Interview</h4>
              </div>
              <div class="box pt-3 px-3 mt-3" v-for="(que, index) in selectedQues" :key="index">
                <div>
                  <div class="question-name">
                    <div class="left">
                      <b-form-input class="form-control" :disabled="true" v-model="que.question"> </b-form-input>
                      <!-- <div
                        v-if="
                          submitted &&
                            (!q[index].que || String(q[index].que).length == 0)
                        "
                        class="validate-error"
                      >
                        <span>{{ "This field is required" }}</span>
                      </div> -->
                      <!-- </ValidationProvider> -->
                    </div>
                    <div class="right ml-auto">
                      <a style="cursor: pointer" @click="delSelectedQues(index)">
                        <img src="@/assets/app-icons/delete.svg" width="25px" />
                      </a>
                    </div>
                  </div>
                  <!--End Question Name-->

                  <div class="question-input">
                    <div class="question-input-inner" v-for="(opt, i) in que.options" :key="i">
                      <div class="left">
                        <b-form-input class="form-control" :disabled="true" v-model="opt.question_option_title" :placeholder="'Enter Option ' + (i + 1)"></b-form-input>
                        <!-- <div
                          v-if="
                            submitted &&
                              (!q[index].options[i].option ||
                                String(q[index].options[i].option).length == 0)
                          "
                          class="validate-error"
                        >
                          <span>{{ "This field is required" }}</span>
                        </div> -->
                      </div>
                      <div class="right d-flex">
                        <b-form-checkbox class="radio-square text-dark" v-model="opt.status" @input="correctAns(index, i)" :unchecked-value="false">Mark As Correct</b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <!--End Question Input-->
                  <div class="question-status pt-4">
                    <div class="score">
                      <!-- <ValidationProvider
                    name="score"
                    :rules="virtualInterview ? 'required' : ''"
                    v-slot="{ errors, classes }"
                  > -->
                      <!-- <b-form-input
                        :disabled="true"
                        v-model="que.score"
                        type="number"
                      >
                      </b-form-input> -->
                      <!-- <div
                        v-if="
                          submitted &&
                            (!q[index].score ||
                              String(q[index].score).length == 0)
                        "
                        class="validate-error"
                      >
                        <span>{{ "This field is required" }}</span>
                      </div> -->
                      <!-- </ValidationProvider> -->
                    </div>
                  </div>
                  <!--End Question Status-->
                </div>
              </div>
            </div>
            <div>
              <div class="grid-box">
                <h4 class="heading">Suggested Virtual Interview Questions</h4>
              </div>
              <div class="my-2 border-left pl-3 question-list">
                <div class="pb-2" v-for="(data, index) in suggestedQue" :key="index">
                  <div class="box p-2">
                    <div class="d-flex align-items-center">
                      <div @click="onSelectQues(data, index)" class="radio-box" />
                      <span class="mr-2">Q{{ index + 1 }}. </span>
                      <b-form-input v-model="data.question" :disabled="true" class="form-control"> </b-form-input>
                    </div>
                    <!--End Question Name-->

                    <div class="question-input">
                      <div>
                        <div class="left" v-for="(opt, i) in data.options" :key="i">
                          <b-form-input class="form-control" v-model="opt.question_option_title" :disabled="true" :placeholder="'Enter Option ' + (i + 1)"></b-form-input>
                        </div>
                      </div>
                    </div>
                    <!--End Question Input-->
                  </div>
                </div>
                <p class="text-center" style="cursor: pointer" @click="showMoreSuggestions">Show More</p>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary text-center mt-2" style="margin-left: 15px" @click="onSubmit">Submit</button>
      </div>
    </div>

    <!--Select Template Modal-->

    <!--Close Modal-->

    <!--Choose Question-->
  </div>
</template>

<script>
import QuestionService from "@/apiServices/QuestionServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
export default {
  data() {
    return {
      suggestedQue: [],
      selectedQues: [],
      deletedQues: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  computed: {},
  beforeMount() {
    // this.getEQTempQuestions();
    this.getSuggestedQues(this.pagination);
  },
  methods: {
    getSuggestedQues(payload) {
      QuestionService.GetAllSuggestedQuestions(payload).then((res) => {
        
        this.suggestedQue = res.data.data.data;
        this.getSuggestedQA(this.pagination);
      });
    },
    getSuggestedQA(payload) {
      ApplicantProfileService.GetSuggestedQuesAns(payload).then((res) => {
        
        this.selectedQues = res.data.data.data;
        console.log("this.suggestedQue ===:>> ", this.suggestedQue);
        this.suggestedQue = this.suggestedQue.filter((f) => !this.selectedQues.some((i) => f.question_id == i.question_id || (f.universal_question_id && i.universal_question_id && f.universal_question_id == i.universal_question_id)));
      });
    },
    showMoreSuggestions() {
      ++this.pagination.currentPage;
      QuestionService.GetAllSuggestedQuestions(this.pagination).then((res) => {
        
        this.suggestedQue = [...this.suggestedQue, ...res.data.data.data];
      });
    },
    onSelectQues(data, index) {
      console.log(`data::::::`, data);
      console.log("index", index);
      this.selectedQues.unshift(data);
      // this.suggestedQue = this.suggestedQue.filter(
      //   (f) => !this.selectedQues.includes(f)
      // );
      this.suggestedQue.splice(index, 1);
    },
    delSelectedQues(i) {
      const [data] = this.selectedQues.splice(i, 1);
      this.deletedQues.push(data);
      this.suggestedQue.push(data);
    },
    correctAns(index, i) {
      if (this.selectedQues[index].options && this.selectedQues[index].options.length && this.selectedQues[index].options[i].status) {
        this.selectedQues[index].options.map((op, opIndex) => {
          if (opIndex != i) {
            op.status = false;
          }
        });
      }
    },
    onSubmit() {
      let payload = {
        selectedQ: this.selectedQues.map((m) => {
          const [opt] = m.options.filter((f) => f.status);
          console.log("opt :>> ", opt);
          return {
            job_id: m.job_id || null,
            company_id: m.company_id || null,
            question_id: m.question_id,
            option_id: opt && opt.question_option_id ? opt.question_option_id : null,
            universal_question_id: m.universal_question_id || null,
            universal_question_option_id: opt && opt.universal_question_option_id ? opt.universal_question_option_id : null,
          };
        }),
      };
      
      payload.selectedQ = payload.selectedQ.filter((f) => f.option_id);
      payload.deletedQ = this.deletedQues;
      ApplicantProfileService.AddAnsSuggestedQues(payload).then((res) => {
        
        if (res.data.status) {
          this.$toasted.show(`${res.data.data}` || "Answers submitted successfully", {
            duration: 6000,
            icon: "check",
          });
        } else {
          this.$toasted.show(`${res.data.data}` || "Failed to submit answers", {
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.validate-error {
  color: #ff4b4b;
  font-size: 13px;
  padding: 4px;
  font-family: "pn-regular";
  margin-top: -15px;
}
.grid-box {
  display: grid;
  grid-template-columns: 60% 40%;
}
.question-list {
  height: 100vh;
  overflow-y: scroll;
  background: white;
}
.radio-box {
  height: 12px;
  width: 12px;
  margin-right: 4px;
  border: 1px solid #6244e5;
}
.radio-box:hover {
  cursor: pointer;
}
</style>
